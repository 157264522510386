import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import parse from "html-react-parser";

import Layout from "../components/layouts/Layout";
import Meetup from "../components/pages/schedule/Meetup";
import HeaderBlock from "../components/pages/index/HeaderBlock";
import SpeakerCard from "../components/cards/SpeakerCard";
import Seo from "../components/main/SEO";
import FormGR from "../components/forms/FormGR";

import SectionLayoutGrid from "../components/styled-components/wrappers/SectionLayoutGrid";
import LinkButton from "../components/styled-components/button/LinkButton";
import Col from "../components/styled-components/wrappers/Col";
import H2 from "../components/styled-components/typography/H2";
import Divider from "../components/styled-components/divider/Divider";
import P from "../components/styled-components/typography/P";
import {
    Api, ButtonContainer,
    TextBlock,
    TextBlockColumn,
    TextBlockIMG,
    TextBlockTitle
} from "../components/styled-components/index/IndexComponents";

const IndexPage = () => {

    const data = useStaticQuery(graphql`
    {
      allStrapiBlackowlMeetups(filter: {isActive: {eq: true}}) {
        activeMeetup: nodes {
          title
          date
        }
      }
      activeEvents: allStrapiBlackowlEvents(filter: {blackowl_meetup: {isActive: {eq: true}}}) {
        active: distinct(field: speaker___slug)
        active_additional: distinct(field: additional_speakers___slug)
      }
      allStrapiBlackowlEvents(
            filter: {blackowl_meetup: {isActive: {eq: true}}, slug: {ne: ""}}
            sort: {order: ASC, fields: date}
          ) {
            activeEvents: nodes {
              date
              slug
              title
              speaker {
                avatar {
                  url
                }
                slug
                name
                position
              }
            }
          }
      allSpeakers: allStrapiBlackowlSpeakers(sort: {fields: name, order: ASC}) {
        all_speakers: nodes {
          slug
          name
          position
          avatar {
            url
          }
        }
      }
      allStrapiBlackowlIndices {
        nodes {
          title
          Hero_Image {
            url
          }
          description
          InfoCards {
            avatar {
              url
            }
            id
            title
          }
          list_token
          SEO {
            isIndexable
            title
            preview {
              url
            }
            keywords
            description
          }
        }
      }
    }
  `)

    const {allStrapiBlackowlEvents: {activeEvents}} = data;
    const {allStrapiBlackowlMeetups: {activeMeetup}} = data;
    const {activeEvents: {active, active_additional}} = data;
    const {allStrapiBlackowlIndices: {nodes}} = data;
    const {allSpeakers: {all_speakers}} = data;
    const isActive = (activeEvents.length !== 0);

    const activeSpeakers = all_speakers.filter(f => active.includes(f.slug) || active_additional.includes(f.slug));

    return (
        <Layout>
            <Seo title={nodes[0].SEO.title} description={nodes[0].SEO.description}
                 keywords={nodes[0].SEO.keywords} url={"/"} image={nodes[0].SEO.preview.url}/>
            <HeaderBlock
                active={activeMeetup[0]}
                infoCards={nodes[0].InfoCards}
                heroImage={nodes[0].Hero_Image.url}
            />
            <SectionLayoutGrid id="About">
                <Col col4 top>
                    <TextBlockIMG src="/assets/pexels-photo.jpeg" alt="pexels"/>
                </Col>
                <Col col8 top marginBottom="medium">
                    <TextBlock>
                        <TextBlockTitle>
                            <div>
                                <H2 medium>{nodes[0].title}</H2>
                                <Divider/>
                            </div>
                            <Api>API</Api>
                        </TextBlockTitle>
                        <TextBlockColumn>
                            <P description>{parse(nodes[0].description)}</P>
                        </TextBlockColumn>
                        <ButtonContainer>
                            <LinkButton to="/#register">Regisztráció</LinkButton>
                        </ButtonContainer>
                    </TextBlock>
                </Col>
            </SectionLayoutGrid>
            {isActive &&
            <SectionLayoutGrid id="Program">
                <Meetup
                    title={activeMeetup[0].title}
                    date={activeMeetup[0].date}
                    events={activeEvents}
                    isIndex
                />
                <Col full center marginBottom="small">
                    <LinkButton to={"/#register"}>Regisztráció</LinkButton>
                </Col>
                <Col full center grid top>
                    <H2 medium>Előadóink</H2>
                    <Divider center/>
                </Col>
                {activeSpeakers.map((item) => (
                        <Col key={item.slug} col3 marginBottom="small">
                            <SpeakerCard
                                name={item.name}
                                avatar={item.avatar.url}
                                position={item.position}
                                slug={item.slug}
                            />
                        </Col>
                ))}
                <Col full center marginBottom="small">
                    <LinkButton to={"/#register"}>Regisztráció</LinkButton>
                </Col>
            </SectionLayoutGrid>
            }
            <SectionLayoutGrid id="register" register>
                <Col full center grid>
                    <H2 medium>Regisztráció a Blackowl-meetupra</H2>
                    <Divider center/>
                </Col>
                <Col full>
                    <FormGR listToken={nodes[0].list_token} />
                </Col>
            </SectionLayoutGrid>
        </Layout>
    )
}

export default IndexPage;
