import React from 'react';
import moment from "moment";

import LinkButton from "../../styled-components/button/LinkButton";
import {
    BigYear, BottomBox,
    DateGrid,
    DateSpan1,
    DateSpan2,
    Day,
    Header, InfoGrid, InfoImg, InfoInnerGrid, InfoText, InfoTextA,
    Month, ScrollGrid, ScrollImg, ScrollLink, ScrollP, SocialGrid, SocialIMG, SocialLink, SocialP, Title,
    TitleBox, TitleGrid, Year, YearGrid
} from "../../styled-components/index/IndexComponents";



const HeaderBlock = ({active, infoCards, heroImage}) => {
    return (
        <Header heroImageUrl={heroImage}>
            <TitleBox>
                <DateGrid>
                    <DateSpan1>
                        <Day>
                            {moment(active.date).locale("hu").format("D")}
                        </Day>
                        <Month>
                            {moment(active.date).locale("hu").format("MMM")}
                        </Month>
                    </DateSpan1>
                    <DateSpan2>
                        <Year>
                            {moment(active.date).format("YYYY")}
                        </Year>
                    </DateSpan2>
                </DateGrid>
                <TitleGrid>
                    <Title>{active.title}</Title>
                    <div>
                        <LinkButton to={"/#register"}>Regisztráció</LinkButton>
                    </div>
                </TitleGrid>
                <YearGrid>
                    <BigYear>{/*moment(active.date).format("YYYY")*/}</BigYear>
                </YearGrid>
            </TitleBox>
            <BottomBox>
                <InfoGrid>
                    <InfoInnerGrid>
                        <InfoImg src={infoCards[0].avatar.url} alt="workshop-icon"/>
                        <InfoText>
                            {infoCards[0].title}
                        </InfoText>
                    </InfoInnerGrid>
                    <InfoInnerGrid>
                        <InfoImg src={infoCards[1].avatar.url} alt="location-icon"/>
                        <InfoText>
                            {infoCards[1].title}
                        </InfoText>
                    </InfoInnerGrid>
                    <InfoInnerGrid>
                        <InfoImg src={infoCards[2].avatar.url} alt="contacts-icon"/>
                        <InfoText>
                            {infoCards[2].title}<br/>
                            <InfoTextA
                                href="mailto:info@balasys.hu?subject=Balasys%20Blackowl%20meetup">info@balasys.hu</InfoTextA>
                        </InfoText>
                    </InfoInnerGrid>
                </InfoGrid>
                <ScrollGrid>
                    <ScrollLink to={"/#About"}>
                        <ScrollP>Görgess</ScrollP>
                        <ScrollImg src="/assets/Icons_arrow-right-icon.svg" alt="arrow"/>
                    </ScrollLink>
                    <SocialGrid>
                        <SocialLink href="https://www.facebook.com/balasysIT" target="_blank" rel="noreferer" bottom top toEnd>
                            <SocialIMG src="/assets/facebook-icon-white.svg" alt="face-logo"/>
                            <SocialP>Facebook</SocialP>
                        </SocialLink>
                        <SocialLink href="https://www.youtube.com/channel/UCk6O503mJSYjbSdXwZi0jNA" target="_blank" rel="noreferer" bottom>
                            <SocialIMG src="/assets/youtube-icon-white.svg" alt="yt-logo"/>
                            <SocialP>YouTube</SocialP>
                        </SocialLink>
                    </SocialGrid>
                </ScrollGrid>
            </BottomBox>
        </Header>
    )
}

export default HeaderBlock