import styled from "styled-components";
import {Link} from "gatsby";

export const TextBlock = styled.div`
  display: grid;
  grid-auto-flow: row;
  padding: 10% 0 0 0;
  @media screen and (max-width: 800px) {
    grid-template-rows: 1fr;
    padding: 0;
  }
`

export const ButtonContainer = styled.div`
  display: grid;
  justify-items: start;
`

export const TextBlockTitle = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 20px;
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`

export const TextBlockColumn = styled.div`
  column-count: 2;
  column-gap: 2em;
  @media screen and (max-width: 800px) {
    column-count: 1;
    margin-bottom: 20px;
  }
`

export const TextBlockIMG = styled.img`
  max-width: 400px;
  width: 100%;
  height: auto;
  @media screen and (max-width: 800px) {
    display: none;
  }
`

export const Api = styled.div`
  color: rgba(224, 222, 233, 0.5);
  font-size: 136px;
  line-height: 120px;
  font-weight: 600;
  text-transform: uppercase;
  opacity: 1;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
  transform-style: preserve-3d;
  text-align: right;
  @media screen and (max-width: 800px) {
    display: none;
  }
`
/*header rész*/

export const Header = styled.div`
  display: grid;
  grid-template-rows: 8.5fr 1.5fr;
  background-repeat: no-repeat;
  background-position: bottom;
  background-color: white;
  background-size: cover;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${props => props.heroImageUrl});
  height: 100vh;
  @media screen and (max-width: 800px) {
    grid-template-rows: 2fr 1fr;
    background-position: 0, 0, 50%, 50%;
  }
  @media screen and (max-height: 650px) {
    height: auto;
    max-height: 700px;
  }
`

export const TitleBox = styled.div`
  display: grid;
  grid-template-columns: 3fr 4fr 5fr;
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr 2fr;
  }
`

export const BottomBox = styled.div`
  display: grid;
  grid-template-columns: 6.5fr 3.5fr;
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: 6.5fr 3.5fr;
  }
`

export const DateGrid = styled.div`
  display: grid;
  max-height: 150px;
  align-self: center;
  justify-self: end;
  padding-right: 30px;
  padding-bottom: 40px;
  border-right: 2px solid white;
  @media screen and (max-width: 800px) {
    max-height: 125px;
    padding-right: 10%;
  }
`

export const TitleGrid = styled.div`
  display: grid;
  max-height: 150px;
  align-self: center;
  justify-self: start;
  padding-left: 30px;
  padding-bottom: 40px;
  @media screen and (max-width: 800px) {
    padding-right: 6vw;
  }
`

export const YearGrid = styled.div`
  display: grid;
  align-self: start;
  justify-self: end;
  margin-top: 13vh;
  @media screen and (max-width: 800px) {
    display: none;
  }
`

export const InfoGrid = styled.div`
  display: grid;
  background-color: #fff;
  padding-left: 20%;
  grid-template-columns: 1fr 1fr 1fr;
  align-content: center;
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    padding: 0;
  }
`

export const ScrollGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 9fr;
  @media screen and (max-width: 800px) {
    max-height: 8vh;
  }
`

export const InfoInnerGrid = styled.div`
  display: grid;
  padding: 40px 10px 40px 10px;
  grid-column-gap: 20px;
  grid-auto-flow: column;
  justify-content: start;
  @media screen and (max-width: 800px) {
    grid-column-gap: 10px;
    padding: 10px 10vw 10px 10vw;
  }
  
`

export const DateSpan1 = styled.span`
  padding: 10px 20px 10px 20px;
  border-left: 2px solid white;
  border-right: 2px solid white;
  border-top: 2px solid white;
  box-sizing: border-box;
  @media screen and (max-width: 800px) {
    padding: 7px 15px 7px 15px;
  }
`

export const DateSpan2 = styled.span`
  padding: 10px 20px 10px 20px;
  border: 2px solid white;
  box-sizing: border-box;
  @media screen and (max-width: 800px) {
    padding: 7px 15px 7px 15px;
  }
`

export const Day = styled.p`
  font-family: Archivo, sans-serif;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 38px;
  line-height: 32px;
  font-weight: 700;
  color: white;
  text-align: center;
`

export const Month = styled.p`
  font-family: Archivo, sans-serif;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 2px;
  color: white;
  text-align: center;
`

export const Year = styled.p`
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 3px;
  color: white;
`

export const Title = styled.h2`
  margin-bottom: 24px;
  font-size: 52px;
  line-height: 64px;
  font-weight: 700;
  color: #fff;
  @media screen and (max-width: 800px) {
    font-size: 30px;
    line-height: 36px;
  }
  @media screen and (max-width: 450px) {
    font-size: 24px;
    line-height: 29px;
  }
`

export const BigYear = styled.h2`
  color: hsla(0, 0%, 100%, 0.15);
  font-size: 11vw;
  font-weight: 600;
  font-family: Archivo, sans-serif;
`

export const InfoImg = styled.img`
  width: 50px;
  color: #ed655a;
`

export const InfoText = styled.p`
  font-family: Archivo, sans-serif;
  color: #1a1725;
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  align-self: center;
`

export const InfoTextA = styled.a`
  color: #ed655a;

  &:hover {
    text-decoration: underline;
  }
`

export const ScrollLink = styled(Link)`
  display: grid;
  grid-template-columns: 2fr 1fr;
  transform: rotate(90deg);
  transition: background-color 200ms ease;
  padding: 12px 12px 12px 12px;
  width: 100px;
  max-width: 100%;
  text-align: center;
  justify-self: center;
  align-self: center;
  color: #fff;
  font-size: 12px;
  letter-spacing: 1px;

  &:hover {
    background-color: hsla(0, 0%, 100%, 0.05);
  }

  
`

export const ScrollImg = styled.img`
  margin-left: 12px;
  align-self: center;
`

export const ScrollP = styled.p`
  justify-self: end;
  align-self: center;
`

export const SocialGrid = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  padding: 50px;
  justify-items: end;
  @media screen and (max-width: 800px) {
    padding: 15px;
  }
`

export const SocialLink = styled.a`
  display: grid;
  grid-template-columns: 1fr 3fr;
  height: 40px;
  width: 120px;
  color: #fff;
  font-size: 13px;
  border-top: ${props => props.top ? "1px solid hsla(0, 0%, 100%, 0.3)" : "unset"};
  border-left: 1px solid hsla(0, 0%, 100%, 0.3);
  border-right: 1px solid hsla(0, 0%, 100%, 0.3);
  border-bottom: ${props => props.bottom ? "1px solid hsla(0, 0%, 100%, 0.3)" : "unset"};
  line-height: 20px;
  align-self: ${props => props.toEnd ? "end" : "start"};

  &:hover {
    background-color: hsla(0, 0%, 100%, 0.05);
  }
`

export const SocialIMG = styled.img`
  width: 12px;
  align-self: center;
  justify-self: center;
`

export const SocialP = styled.p`
  margin-left: 5px;
  align-self: center;
  justify-self: start;
`